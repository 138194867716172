import React from "react";
import Layout from "@components/layout";
import FoodItem from "@components/menu/FoodItem";
import DrinkItem from "@components/menu/DrinkItem";
import SEO from "@components/seo";
import Menu from "@components/menu/menu";
import { graphql } from "gatsby";

function MenuPage({ data }) {
  return (
    <div>
      <Layout>
        <SEO
          keywords={[`Soi 29`, `Thai`, `Restaurant`, `Malmö`]}
          title="Menu | SOI 29 - Thai street food &amp; bar"
        />

        <Menu />
      </Layout>
    </div>
  );
}

export default MenuPage;

export const query = graphql`
  {
    allWordpressWpFood {
      edges {
        node {
          id
          title
          acf {
            description
            price
          }
        }
      }
    }
    allWordpressWpDrinks {
      edges {
        node {
          title
          acf {
            drink_type
            drink_description
          }
        }
      }
    }
  }
`;
